.container {
  display: flex;
  flex-direction: column;
  margin: 70px 0;

  .tagsMain {
    max-width: 500px;
    width: calc(100% - 20px);
    align-self: center;

    justify-content: center;
  }

  .tags {
    max-width: 500px;
    width: calc(100% - 20px);
    align-self: center;

    margin-left: 10px;
    margin-top: 200px;
  }
}
