.container {
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;

  &:hover .titleOverlay {
    opacity: 1;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .mobileTitle {
    display: none;
  }

  .titleOverlay {
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
    display: flex;
    align-items: center;
    color: var(--colorWhite);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    height: 35px;
    font-weight: var(--fontBold);

    .content {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 10px;
    }
  }

  @media screen and (max-width: 1023px) {
    .mobileTitle {
      display: block;
      width: 100%;

      color: var(--colorC);
      font-weight: var(--fontBold);
      padding: 12px 0 10px;

      border-top: 1px solid var(--color38);
    }

    .titleOverlay {
      display: none;
    }
  }
}
