.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .tag {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 7px;
    font-size: 14px;
    margin-bottom: 8px;

    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  .tagName {
    background-color: var(--colorC);
    color: var(--colorBlack);
    font-weight: var(--fontBold);
  }

  .tagValue {
    background-color: var(--color96);
    color: var(--colorWhite);
    font-weight: var(--fontBold);
    text-transform: capitalize;
    cursor: pointer;

    &:hover {
      background-color: var(--color60);
    }
  }
}
