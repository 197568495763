.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(var(--colorBlack), var(--color30));
  border-top: 1px solid var(--color29);
  padding: 20px 20px;

  .links {
    color: var(--color8);
    font-size: 13px;
    text-align: center;

    a {
      color: var(--color8);
    }
  }

  .notice {
    color: var(--colorC);
    font-size: 11px;
    font-weight: var(--fontSemiBold);
    max-width: 900px;
    margin-top: 15px;

    span {
      color: var(--colorYellowNotice);
    }
  }
}
