.container {
  display: flex;
  background: linear-gradient(var(--color2F), var(--colorBlack));
  border-bottom: 1px solid var(--color29);
  height: 70px;
  align-items: center;
  justify-content: center;

  .logo {
    position: relative;
    cursor: pointer;

    img {
      max-width: 250px;
    }

    .subtitle {
      position: absolute;
      right: 5px;
      bottom: 5px;
      color: var(--colorB4);
      font-size: 10px;
      font-weight: var(--fontBold);
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
}
