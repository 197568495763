:root {
  --colorBlack: #000;
  --colorWhite: #fff;

  --color0F: #0f0f0f;
  --color26: #262626;
  --color29: #292929;
  --color2F: #2f2f2f;
  --color30: #303030;
  --color38: #383838;
  --colorB4: #b4b4b4;
  --color8: #888;
  --colorC: #ccc;

  --color60: #600000;
  --color96: #960000;

  --colorYellowNotice: #e5e51d;

  --fontLight: 300;
  --fontRegular: 400;
  --fontSemiBold: 600;
  --fontBold: 700;
}
