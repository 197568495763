.container {
  display: flex;
  flex-direction: column;
  margin: 70px 0;

  .refresh {
    max-width: 500px;
    width: calc(100% - 25px);
    align-self: center;

    margin-top: 20px;
  }

  .tags {
    max-width: 500px;
    width: calc(100% - 20px);
    align-self: center;

    margin-top: 200px;
  }
}
