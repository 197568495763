.container {
  display: flex;
  justify-content: center;
  padding: 3px;
  background-color: var(--color26);
  cursor: pointer;

  .text {
    text-transform: uppercase;
    font-size: 24px;
    font-weight: var(--fontBold);
    color: var(--colorC);
  }
}
