.container {
  display: flex;
  flex-direction: column;

  .body {
    display: flex;
    flex-direction: column;
    background-color: var(--colorBlack);
    min-height: 100vh;
    align-items: center;
  }
}
