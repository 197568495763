.container {
  display: flex;
  flex-direction: column;
  margin: 70px 0;

  max-width: 910px;
  width: 100%;

  .detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--color0F);
    border-radius: 4px;
    padding: 8px;

    .title {
      align-self: flex-start;
      color: var(--colorC);
      font-size: 24px;
      font-weight: var(--fontBold);
      margin-bottom: 15px;
      width: 100%;
    }

    .video {
      width: 100%;
      // max-width: 500px;
      position: relative;
      padding-top: 50.04359197907585%;
    }

    .tags {
      align-self: flex-start;
      margin-top: 10px;
    }
  }

  .relatedIndex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: var(--color0F);
    border-radius: 4px;
    padding: 8px;
    margin-top: 15px;

    .detailView {
      width: calc(50% - 2px);
      margin-bottom: 4px;

      &:nth-child(odd) {
        margin-right: 2px;
      }

      &:nth-child(even) {
        margin-left: 2px;
      }
    }
  }

  .tags {
    margin-top: 200px;
    padding: 8px;
  }

  @media screen and (max-width: 1023px) {
    .detail {
      .title {
        font-size: 18px;
      }
    }

    .relatedIndex {
      flex-direction: column;
      flex-wrap: initial;
      align-items: center;

      .detailView {
        width: calc(100% - 4px);
      }
    }
  }
}
