.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--colorWhite);

  .detail {
    max-width: 500px;
    width: calc(100% - 20px);

    &:not(:first-child) {
      margin-top: 20px;
    }
  }
}
